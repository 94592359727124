
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root{
    height: 100%;
}

body{
    background-color: #990000;
    font-family: sans-serif;
}

.app{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.app > h4{
    color: white;
    font-style: italic;
    font-size: 15px;
}